<template>
  <div style="height: 100%">
    <van-nav-bar
      :title="'全部评价（' + list.length + '条）'"
      left-arrow
      @click-left="$goBack()"
      fixed
      placeholder
    >
    </van-nav-bar>
    <van-list
      v-if="list.length"
      style="height: calc(100vh - 46px); overflow: scroll"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <evaluate-item
        v-for="(item, index) in list"
        :key="index"
        :value="item"
      ></evaluate-item>
    </van-list>
    <van-empty description="暂无评论" v-else></van-empty>
  </div>
</template>

<script>
import { getComment } from "../../api/hotelDetail/index";
import evaluateItem from "@/components/evaluate-item";
export default {
  name: "detailsGoodsEvaluate",
  props: {},
  components: { evaluateItem },
  data() {
    return {
      id: null,
      current: 1,
      list: [],

      loading: false,
      finished: true,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getComment();
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    async getComment() {
      // let res = await getComment({ current: this.current });
      let res = await getComment({ spuId: this.id, current: this.current });
      console.log(res);
      this.loading = false;
      this.list = [...this.list, ...res.data.data.records];
      if (this.list.length == res.data.data.total) {
        this.finished = true;
      }
    },
    onLoad() {
      this.current++;
      this.getComment();
    },
  },
};
</script>

<style lang='less' scoped>
</style>